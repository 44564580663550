import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import './RepaymentChart.css';

function RepaymentChart({ data }) {
  const svgRef = useRef();

  useEffect(() => {
    // SVG要素の設定
    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove(); // 前回の描画をクリア

    const width = 800;
    const height = 400;
    const margin = { top: 20, right: 30, bottom: 50, left: 75 };

    svg.attr('width', width).attr('height', height);

    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    // データの準備
    const xScale = d3
      .scaleBand()
      .domain(data.map((d) => d.month))
      .range([0, innerWidth])
      .padding(0.1);

    const yMax = d3.max(data, (d) => d.totalPayment);

    const yScale = d3
      .scaleLinear()
      .domain([0, yMax])
      .range([innerHeight, 0]);

    const color = d3.scaleOrdinal()
      .domain(['principalPayment', 'interestPayment'])
      .range(['#69b3a2', '#404080']);

    // スタックデータの準備
    const stackedData = d3.stack()
      .keys(['principalPayment', 'interestPayment'])
      (data);

    // グラフエリア
    const graph = svg.append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    // 軸の描画
    graph.append('g')
      .attr('transform', `translate(0, ${innerHeight})`)
      .call(d3.axisBottom(xScale).tickValues(xScale.domain().filter((d, i) => d % 12 === 0)))
      .selectAll("text")
      .attr("transform", "rotate(-45)")
      .style("text-anchor", "end");

    graph.append('g')
      .call(d3.axisLeft(yScale));

    // バーの描画
    const bars = graph.selectAll('g.layer')
      .data(stackedData)
      .enter()
      .append('g')
      .attr('class', 'layer')
      .attr('fill', (d) => color(d.key))
      .selectAll('rect')
      .data((d) => d)
      .enter()
      .append('rect')
      .attr('x', (d) => xScale(d.data.month))
      .attr('y', (d) => yScale(d[1]))
      .attr('height', (d) => yScale(d[0]) - yScale(d[1]))
      .attr('width', xScale.bandwidth())
      .attr('class', (d) => d.data.is125RuleApplied ? 'bar-125-rule' : '');

    // 矢印の定義をループの外に移動
    svg.append('defs').append('marker')
      .attr('id', 'arrow')
      .attr('markerWidth', 10)
      .attr('markerHeight', 10)
      .attr('refX', 0)
      .attr('refY', 3)
      .attr('orient', 'auto')
      .append('path')
      .attr('d', 'M0,0 L0,6 L9,3 z')
      .style('fill', 'red');

    // 金利変更イベントの矢印表示
    data.forEach(d => {
      if (d.isRateChangeMonth) {
        graph.append('line')
          .attr('x1', xScale(d.month) + xScale.bandwidth() / 2)
          .attr('x2', xScale(d.month) + xScale.bandwidth() / 2)
          .attr('y1', innerHeight)
          .attr('y2', innerHeight - 10)
          .attr('stroke', 'red')
          .attr('stroke-width', 2)
          .attr('marker-end', 'url(#arrow)');
      }
    });

    // 以下、残りのコードはそのまま

    // ツールチップの設定
    const tooltip = d3.select('body').append('div')
      .attr('class', 'tooltip')
      .style('opacity', 0);

    // ホバーイベントの追加
    bars.on('mouseover', (event, d) => {
      tooltip.transition()
        .duration(200)
        .style('opacity', 0.9);
      tooltip.html(
        `月: ${d.data.month}<br/>
        元金: ¥${Math.round(d.data.principalPayment).toLocaleString()}<br/>
        利息: ¥${Math.round(d.data.interestPayment).toLocaleString()}<br/>
        合計: ¥${Math.round(d.data.totalPayment).toLocaleString()}`
      )
        .style('left', (event.pageX + 15) + 'px')
        .style('top', (event.pageY - 28) + 'px');
    })
      .on('mouseout', () => {
        tooltip.transition()
          .duration(500)
          .style('opacity', 0);
      });

    // 凡例の追加
    const legend = svg.append('g')
      .attr('transform', `translate(${width - margin.right - 120}, ${margin.top})`);

    legend.selectAll('rect')
      .data(['元金', '利息'])
      .enter()
      .append('rect')
      .attr('x', 0)
      .attr('y', (d, i) => i * 20)
      .attr('width', 18)
      .attr('height', 18)
      .attr('fill', (d, i) => color(i === 0 ? 'principalPayment' : 'interestPayment'));

    legend.selectAll('text')
      .data(['元金', '利息'])
      .enter()
      .append('text')
      .attr('x', 24)
      .attr('y', (d, i) => i * 20 + 9)
      .attr('dy', '0.35em')
      .text((d) => d);
  }, [data]);

  return (
    <div className="chart-container">
      <h2>返済額の推移</h2>
      <svg ref={svgRef}></svg>
    </div>
  );
}

export default RepaymentChart;
