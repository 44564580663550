import React, { useState } from 'react';
import LoanInputForm from './components/LoanInputForm';
import RepaymentChart from './components/RepaymentChart';
import BalanceChart from './components/BalanceChart';
import './App.css';

function App() {
  const [calculationData, setCalculationData] = useState(null);
  const [unpaidInterestOccurred, setUnpaidInterestOccurred] = useState(false);

  return (
    <div className="App">
      <LoanInputForm
        setCalculationData={setCalculationData}
        setUnpaidInterestOccurred={setUnpaidInterestOccurred}
      />
      {unpaidInterestOccurred && (
        <div className="alert">
          未払い利息が発生しています。詳細については専門家にご相談ください。
        </div>
      )}
      {calculationData && (
        <>
          {/* 総返済額と総利息額を表示 */}
          <div className="totals">
            <h2>総返済額と総利息額</h2>
            <p>
              総返済額: ¥{Math.round(calculationData.totalPaymentAmount).toLocaleString()}
            </p>
            <p>
              総利息額: ¥{Math.round(calculationData.totalInterestPaid).toLocaleString()}
            </p>
          </div>
          <RepaymentChart data={calculationData.repayments} />
          <BalanceChart data={calculationData.balanceOverTime} />
        </>
      )}
    </div>
  );
}

export default App;
