import React, { useState } from 'react';
import './LoanInputForm.css';

function LoanInputForm({ setCalculationData, setUnpaidInterestOccurred }) {
  const [loanAmount, setLoanAmount] = useState(100000000); // 初期値を10,000万円に設定
  const [interestRate, setInterestRate] = useState(0.6); // 初期金利を0.6%に設定
  const [loanTerm, setLoanTerm] = useState(35); // デフォルトの返済期間を35年に変更
  const [repaymentMethod, setRepaymentMethod] = useState('equalPrincipalInterest');
  const [applyFiveYearRule, setApplyFiveYearRule] = useState(false); // 5年ルール適用のトグル
  const [apply125Rule, setApply125Rule] = useState(false); // 125%ルール適用のトグル
  const [interestRateChanges, setInterestRateChanges] = useState([]);
  const [showWarning, setShowWarning] = useState(false); // 警告メッセージ表示用

  const handleLoanAmountChange = (e) => {
    const value = e.target.value.replace(/,/g, '');
    if (!isNaN(value)) {
      setLoanAmount(Number(value) * 10000);
    }
  };

  const formatNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // 「元金均等返済」で5年ルールや125%ルールが選択されている場合、警告を表示
    if (
      repaymentMethod === 'equalPrincipal' &&
      (applyFiveYearRule || apply125Rule)
    ) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }

    const loanData = {
      loanAmount,
      interestRate,
      loanTerm,
      repaymentMethod,
      interestRateChanges,
      applyFiveYearRule,
      apply125Rule,
    };

    const result = calculateLoan(loanData);
    setCalculationData(result);

    // 未払い利息が発生したかを確認
    const unpaidInterestOccurred = result.repayments.some(r => r.unpaidInterest > 0);
    setUnpaidInterestOccurred(unpaidInterestOccurred);
  };

  const calculateLoan = (data) => {
    const {
      loanAmount,
      interestRate,
      loanTerm,
      repaymentMethod,
      interestRateChanges,
      applyFiveYearRule,
      apply125Rule,
    } = data;

    const monthlyTerm = loanTerm * 12;
    let remainingBalance = loanAmount;
    let currentRate = interestRate; // 現在の金利を追跡
    let monthlyInterestRate = currentRate / 100 / 12;
    const repayments = [];
    const balanceOverTime = [];

    let initialMonthlyPayment = 0;

    if (repaymentMethod === 'equalPrincipalInterest') {
      initialMonthlyPayment =
        (loanAmount * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, monthlyTerm)) /
        (Math.pow(1 + monthlyInterestRate, monthlyTerm) - 1);
    } else {
      // 元金均等返済の場合は、毎月の元金返済額を計算
      var principalPaymentPerMonth = loanAmount / monthlyTerm;
    }

    let fiveYearFixedPayment = initialMonthlyPayment;
    let rateChangeAppliedMonth = null;
    let totalPayment = initialMonthlyPayment;
    let previousRate = null;

    let changesIndex = 0;
    interestRateChanges.sort((a, b) => a.year - b.year);

    let unpaidInterest = 0; // 未払い利息の累積

    // 5年ルールの管理用
    let lastRateChangeMonth = 1;
    let nextPaymentIncreaseMonth = null;

    // 総返済額と総利息額の初期化
    let totalInterestPaid = 0;
    let totalPrincipalPaid = 0;

    for (let month = 1; month <= monthlyTerm; month++) {
      // 利率変更の確認と適用
      if (
        changesIndex < interestRateChanges.length &&
        month === interestRateChanges[changesIndex].year * 12 + 1
      ) {
        // 変更前の金利を保存
        previousRate = currentRate;
        currentRate = interestRateChanges[changesIndex].rate;
        monthlyInterestRate = currentRate / 100 / 12;
        changesIndex++;

        if (repaymentMethod === 'equalPrincipalInterest') {
          const remainingTerm = monthlyTerm - month + 1;

          // 新しい金利で返済額を再計算
          initialMonthlyPayment =
            (remainingBalance * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, remainingTerm)) /
            (Math.pow(1 + monthlyInterestRate, remainingTerm) - 1);

          rateChangeAppliedMonth = month;

          // 5年ルール適用中の返済額を保存
          if (applyFiveYearRule) {
            fiveYearFixedPayment = totalPayment;
            lastRateChangeMonth = month;
            nextPaymentIncreaseMonth = month + 60; // 次に支払い額が増える月
          }
        }
      }

      let interestPayment = remainingBalance * monthlyInterestRate;
      let principalPayment;

      if (repaymentMethod === 'equalPrincipalInterest') {
        // 5年ルールの適用
        if (applyFiveYearRule && month < nextPaymentIncreaseMonth) {
          totalPayment = fiveYearFixedPayment;
        } else {
          totalPayment = initialMonthlyPayment;
        }

        // 125%ルールの適用
        if (apply125Rule && month === nextPaymentIncreaseMonth) {
          const maxPayment = fiveYearFixedPayment * 1.25;
          if (totalPayment > maxPayment) {
            totalPayment = maxPayment;
          }
          fiveYearFixedPayment = totalPayment; // 次の5年期間の固定返済額を更新
          lastRateChangeMonth = month;
          nextPaymentIncreaseMonth = month + 60;
        }

        principalPayment = totalPayment - interestPayment;
      } else {
        // 元金均等返済
        principalPayment = principalPaymentPerMonth;
        totalPayment = principalPayment + interestPayment;
      }

      // 未払い利息の計算
      if (principalPayment < 0) {
        unpaidInterest += -principalPayment;
        principalPayment = 0;
      }

      // 元金返済額が残高を超えないように調整
      if (principalPayment > remainingBalance) {
        principalPayment = remainingBalance;
        totalPayment = principalPayment + interestPayment;
      }

      remainingBalance -= principalPayment;

      if (remainingBalance < 0) {
        remainingBalance = 0;
      }

      // 総利息額と総元金返済額の更新
      totalInterestPaid += interestPayment;
      totalPrincipalPaid += principalPayment;

      repayments.push({
        month,
        interestPayment,
        principalPayment,
        totalPayment,
        unpaidInterest, // 未払い利息を追加
        isFiveYearRuleApplied:
          applyFiveYearRule &&
          repaymentMethod === 'equalPrincipalInterest' &&
          month < nextPaymentIncreaseMonth,
        is125RuleApplied:
          apply125Rule &&
          repaymentMethod === 'equalPrincipalInterest' &&
          month === nextPaymentIncreaseMonth,
        isRateChangeMonth: month === rateChangeAppliedMonth, // 金利変更の月を示すフラグを追加
        previousRate: month === rateChangeAppliedMonth ? previousRate : null,
        newRate: month === rateChangeAppliedMonth ? currentRate : null,
      });

      balanceOverTime.push({
        month,
        remainingBalance: remainingBalance / 10000, // 残高の単位を万円に変換
        unpaidInterest, // 未払い利息を追加
        isFiveYearRuleApplied:
          applyFiveYearRule &&
          repaymentMethod === 'equalPrincipalInterest' &&
          month < nextPaymentIncreaseMonth,
        is125RuleApplied:
          apply125Rule &&
          repaymentMethod === 'equalPrincipalInterest' &&
          month === nextPaymentIncreaseMonth,
        isRateChangeMonth: month === rateChangeAppliedMonth, // 金利変更の月を示すフラグ
        previousRate: month === rateChangeAppliedMonth ? previousRate : null,
        newRate: month === rateChangeAppliedMonth ? currentRate : null,
      });

      if (remainingBalance <= 0 && unpaidInterest <= 0) {
        break;
      }
    }

    const totalPaymentAmount = totalInterestPaid + totalPrincipalPaid;

    return {
      repayments,
      balanceOverTime,
      totalInterestPaid,
      totalPrincipalPaid,
      totalPaymentAmount,
    };
  };

  const addInterestRateChange = () => {
    setInterestRateChanges([...interestRateChanges, { year: '', rate: '' }]);
  };

  const handleInterestRateChange = (index, field, value) => {
    const newChanges = [...interestRateChanges];
    newChanges[index][field] = value;
    setInterestRateChanges(newChanges);
  };

  const removeInterestRateChange = (index) => {
    const newChanges = [...interestRateChanges];
    newChanges.splice(index, 1);
    setInterestRateChanges(newChanges);
  };

  return (
    <form onSubmit={handleSubmit} className="loan-form">
      <h2>ローンの詳細</h2>
      <div className="form-group">
        <label>
          借入金額 (万円):
          <input
            type="text"
            value={formatNumberWithCommas(loanAmount / 10000)}
            onChange={handleLoanAmountChange}
            required
          />
        </label>
        <label>
          利率 (%):
          <input
            type="number"
            step="0.01"
            value={interestRate}
            onChange={(e) => setInterestRate(Number(e.target.value))}
            required
          />
        </label>
      </div>
      <div className="form-group">
        <label>
          返済期間 (年):
          <input
            type="number"
            value={loanTerm}
            onChange={(e) => setLoanTerm(Number(e.target.value))}
            required
          />
        </label>
        <label>
          返済方法:
          <select
            value={repaymentMethod}
            onChange={(e) => setRepaymentMethod(e.target.value)}
          >
            <option value="equalPrincipalInterest">元利均等返済</option>
            <option value="equalPrincipal">元金均等返済</option>
          </select>
        </label>
      </div>
      <div className="form-group">
        <label className="checkbox-label">
          5年ルールを適用する
          <input
            type="checkbox"
            checked={applyFiveYearRule}
            onChange={(e) => {
              setApplyFiveYearRule(e.target.checked);
              handleSubmit(e); // トグル変更時に再計算を実行
            }}
          />
        </label>
        <label className="checkbox-label">
          125％ルールを適用する
          <input
            type="checkbox"
            checked={apply125Rule}
            onChange={(e) => {
              setApply125Rule(e.target.checked);
              handleSubmit(e); // トグル変更時に再計算を実行
            }}
          />
        </label>
      </div>

      {/* 警告メッセージを表示 */}
      {showWarning && (
        <div className="warning">
          <p>
            「元金均等返済」を選択した場合、5年ルールや125％ルールは適用されないことが一般的です。<br />
            以下の計算では5年ルール及び125%ルールは選択にかかわらず適用していません。
          </p>
        </div>
      )}

      <h3>利率変更の設定</h3>
      {interestRateChanges.map((change, index) => (
        <div key={index} className="interest-rate-change">
          <label>
            年数:
            <input
              type="number"
              value={change.year}
              onChange={(e) =>
                handleInterestRateChange(index, 'year', Number(e.target.value))
              }
              required
            />
          </label>
          <label>
            新しい利率 (%):
            <input
              type="number"
              step="0.01"
              value={change.rate}
              onChange={(e) =>
                handleInterestRateChange(index, 'rate', Number(e.target.value))
              }
              required
            />
          </label>
          <button type="button" onClick={() => removeInterestRateChange(index)} className="remove-button">
            削除
          </button>
        </div>
      ))}
      <button type="button" onClick={addInterestRateChange} className="add-button">
        利率変更を追加
      </button>
      <br />
      <button type="submit" className="submit-button">計算</button>

      {/* 免責事項を追加 */}
      <div className="disclaimer">
        <p>
          ※この計算結果はあくまで概算であり、実際のローン条件とは異なる場合があります。正確な情報については金融機関等の専門家にご相談ください。
        </p>
        <p>
          ※複数の利率変更を追加した場合、計算結果が正確でない可能性があります。詳細なシミュレーションには専門家の助言を求めてください。
        </p>
      </div>
    </form>
  );
}

export default LoanInputForm;
