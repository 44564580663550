import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import './BalanceChart.css';

function BalanceChart({ data }) {
  const svgRef = useRef();

  useEffect(() => {
    // SVG要素の設定
    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove(); // 前回の描画をクリア

    const width = 800;
    const height = 400;
    const margin = { top: 20, right: 30, bottom: 50, left: 75 };

    svg.attr('width', width).attr('height', height);

    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    // データの準備
    const xScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.month)])
      .range([0, innerWidth]);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.remainingBalance)])
      .range([innerHeight, 0]);

    // グラフエリア
    const graph = svg.append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    // 軸の描画
    graph.append('g')
      .attr('transform', `translate(0, ${innerHeight})`)
      .call(d3.axisBottom(xScale));

    graph.append('g')
      .call(d3.axisLeft(yScale).tickFormat((d) => `${d} 万円`)); // 残高の単位を「万円」に変更

    // ラインの描画
    const line = d3
      .line()
      .x((d) => xScale(d.month))
      .y((d) => yScale(d.remainingBalance));

    graph
      .append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', '#ff6600')
      .attr('stroke-width', 2)
      .attr('d', line);

    // 矢印の定義をループの外に移動
    svg.append('defs').append('marker')
      .attr('id', 'arrow')
      .attr('markerWidth', 10)
      .attr('markerHeight', 10)
      .attr('refX', 0)
      .attr('refY', 3)
      .attr('orient', 'auto')
      .append('path')
      .attr('d', 'M0,0 L0,6 L9,3 z')
      .style('fill', 'blue');

    // 金利変更イベントの矢印表示
    data.forEach(d => {
      if (d.isRateChangeMonth) {
        graph.append('line')
          .attr('x1', xScale(d.month))
          .attr('x2', xScale(d.month))
          .attr('y1', innerHeight)
          .attr('y2', innerHeight - 15)
          .attr('stroke', 'red')
          .attr('stroke-width', 2)
          .attr('marker-end', 'url(#arrow)');

        // 金利変更のラベル
        graph.append('text')
          .attr('x', xScale(d.month) + 5)
          .attr('y', innerHeight - 20)
          .attr('fill', 'red')
          .text(`金利変更: ${d.previousRate}% → ${d.newRate}%`)
          .style('font-size', '12px');
      }
    });

    // ツールチップの設定
    const tooltip = d3.select('body').append('div')
      .attr('class', 'tooltip')
      .style('opacity', 0);

    // ホバー用の透明な領域を作成
    graph.selectAll('dot')
      .data(data)
      .enter()
      .append('circle')
      .attr('cx', (d) => xScale(d.month))
      .attr('cy', (d) => yScale(d.remainingBalance))
      .attr('r', 5)
      .attr('fill', 'transparent')
      .attr('stroke', 'none')
      .on('mouseover', (event, d) => {
        tooltip.transition()
          .duration(200)
          .style('opacity', 0.9);
        tooltip.html(
          `月: ${d.month}<br/>
          残高: ¥${Math.round(d.remainingBalance).toLocaleString()} 万円`
        )
          .style('left', (event.pageX + 15) + 'px')
          .style('top', (event.pageY - 28) + 'px');
      })
      .on('mouseout', () => {
        tooltip.transition()
          .duration(500)
          .style('opacity', 0);
      });
  }, [data]);

  return (
    <div className="chart-container">
      <h2>残高の推移</h2>
      <svg ref={svgRef}></svg>
    </div>
  );
}

export default BalanceChart;
